<template>
  <div data-id="atom-load-spinner" :class="variants"></div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { AtomLoadSpinnerProps } from '~common/types'

const props = defineProps<AtomLoadSpinnerProps>()

const variants = computed(() => {
  return {
    'loader-inner': props.inner,
    'loader-outer': props.outer,
    'loader-on-page': props.onPage,
  }
})
</script>

<style lang="scss" scoped>
.loader-inner {
  margin: 0 14px 8px 5px;
  font-size: 1rem;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  left: -1rem;

  &::after {
    content: ' ';
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid $grey-100-borders;
    border-color: $grey-100-borders transparent $grey-100-borders transparent;
    animation: loading 1.2s linear infinite;
  }
}

.loader-outer {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;

  &::after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid $grey-100-borders;
    border-color: $grey-100-borders transparent $grey-100-borders transparent;
    animation: loading 1.2s linear infinite;
  }
}

.loader-on-page {
  display: block;

  &::after {
    content: ' ';
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid $base-red;
    border-color: $base-red transparent $base-red transparent;
    animation: loading 1.2s linear infinite;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: reduce) {
  .loader-inner::after,
  .loader-outer::after,
  .loader-on-page::after {
    animation: none;
  }
}
</style>
